import React, { useEffect, useState } from 'react'
import { GetServerSideProps } from 'next'
import { useCookies } from 'react-cookie'
import { get } from 'lodash'

import environment from 'environment'

import { DeckListResponse, defaultDeckListResponse } from 'services/apiTypes/deck.types'
import { AGE } from 'types/active'

import { RequestService } from 'services/request.service'
import DeckService from 'services/deck.service'

import CommunityPage, { EMPTY_COMMUNITY, Props } from 'pages/community'
import PageWrapper from 'components/misc/PageWrapper'
import Landing from 'components/homePage/Landing'
import DeckGroup from 'components/homePage/DeckGroup'
import NewsGroup from 'components/homePage/NewsGroup'
import FeaturesGroup from 'components/homePage/FeaturesGroup'
import InlineAd from 'components/advertisments/InlineAd'
import CustomAd from 'components/advertisments/CustomAd'
import Tabs from 'components/homePage/Tabs'

import { responseToCard } from 'utils/ResponseToResults'
import { DEFAULT_DESCRIPTION } from 'utils/DefaultDescription'
import { LOG_ERROR_AND_REDIRECT } from 'utils/ErrorString'

import styles from './index.module.scss'

const HomePage = (props: Props) => {
  const { news, features } = props.home

  const [yourDecksLoading, setYourDecksLoading] = useState(true)
  const [recentlyViewedLoading, setRecentlyViewedLoading] = useState(true)
  const [followedDecksLoading, setFollowedDecksLoading] = useState(true)
  const [myPackagesLoading, setMyPackagesLoading] = useState(true)

  const [yourDecksResults, setYourDecksResults] = useState<DeckListResponse>(defaultDeckListResponse)
  const [recentlyViewedResults, setRecentlyViewedResults] = useState<DeckListResponse>(defaultDeckListResponse)
  const [followedDecksResults, setFollowedDecksResults] = useState<DeckListResponse>(defaultDeckListResponse)
  const [myPackagesResults, setMyPackagesResults] = useState<DeckListResponse>(defaultDeckListResponse)

  const [{ tbId: userId, tbUser: username, tbBanner: _banner }, setCookies] = useCookies(['tbId', 'tbUser', 'tbBanner'])

  useEffect(() => {
    if (!userId) return // Do nothing. We're "redirecting" to the community page

    setYourDecksLoading(true)
    DeckService.getMyDecks()
      .then(data => {
        setYourDecksResults(data)
        setCookies('tbBanner', get(data, 'results[0].featured', ''), { maxAge: AGE.MONTH, path: '/' })
      })
      .finally(() => setYourDecksLoading(false))

    setRecentlyViewedLoading(true)
    DeckService.getMyRecentDecks()
      .then(data => setRecentlyViewedResults(data))
      .finally(() => setRecentlyViewedLoading(false))

    setFollowedDecksLoading(true)
    DeckService.getFollowedDecks()
      .then(data => setFollowedDecksResults(data))
      .finally(() => setFollowedDecksLoading(false))

    setMyPackagesLoading(true)
    DeckService.getMyPackages()
      .then(data => setMyPackagesResults(data))
      .finally(() => setMyPackagesLoading(false))
  }, [userId])

  if (!userId) return <CommunityPage {...props} />

  return (
    <PageWrapper title="MTG Deck Builder - Archidekt" description={DEFAULT_DESCRIPTION} className={styles.container}>
      <Landing />
      <InlineAd banner longBanner id="home-1" removeOnNoAd />

      <Tabs className={styles.containWidth} />

      <DeckGroup
        title="My Decks"
        secondary="View my decks"
        className={styles.containWidth}
        decks={yourDecksResults.results}
        href={`/search/decks/?owner=${username}&ownerexact=true`}
        loading={yourDecksLoading}
      />

      <NewsGroup news={news} className={styles.containWidth} />

      <CustomAd />

      <DeckGroup
        title="Followed Users"
        className={styles.containWidth}
        decks={followedDecksResults.results}
        loading={followedDecksLoading}
      />

      <DeckGroup
        noTags
        title="My Card Packages"
        secondary="View my packages"
        href={`/search/decks/?owner=${username}&ownerexact=true&packages=true`}
        className={styles.containWidth}
        decks={myPackagesResults.results}
        loading={myPackagesLoading}
      />

      <DeckGroup
        title="Recently Viewed"
        className={styles.containWidth}
        decks={recentlyViewedResults.results}
        loading={recentlyViewedLoading}
      />

      {(!!followedDecksResults.results.length || !!recentlyViewedResults.results.length) && (
        <InlineAd id="home-2" banner longBanner removeOnNoAd allowTallMobile />
      )}

      <FeaturesGroup features={features} className={styles.containWidth} />

      <InlineAd id="home-3" banner longBanner removeOnNoAd allowTallMobile />
    </PageWrapper>
  )
}

export const getServerSideProps: GetServerSideProps<Props> = async context => {
  try {
    const userId = context.req.cookies.tbId || 0

    const home = await new RequestService(context).get(environment.staticHomePageURL, {}, true)
    const community = userId // If we're logged in, we don't need the community page data (since it's not shown)
      ? EMPTY_COMMUNITY
      : await new RequestService(context).get(environment.staticCommunityPageURL, {}, true)

    return {
      props: {
        home,
        community: {
          ...community,
          topCards: community.topCards.map(responseToCard),
          topCommanders: community.topCommanders.map(responseToCard),
        },
      },
    }
  } catch (err) {
    return LOG_ERROR_AND_REDIRECT(err)
  }
}

export default HomePage
